import 'leaflet/dist/leaflet'
import 'leaflet-ajax/dist/leaflet.ajax'
import 'leaflet.markercluster/dist/leaflet.markercluster'
import GestureHandling from 'leaflet-gesture-handling/dist/leaflet-gesture-handling'
import 'leaflet-responsive-popup'

// Config //

    class MapConfig {
        constructor(mapDivId) {
            this.mapDiv = $('#'+mapDivId);
            this.startUrl = this.mapDiv.data('start-url');
            this.breadCrumb = $('#' + this.mapDiv.data('breadcrumb-nav-id') + ' ol');
            this.breadCrumbUrl = $('#' + this.mapDiv.data('breadcrumb-nav-id')).data('url');
            this.latLong = [this.mapDiv.data('lat'), this.mapDiv.data('lon')];
            this.zoom = this.mapDiv.data('zoom');
            this.regionSelect = $('#' + this.mapDiv.data('region-select-id'));
            this.hafHiddenInput = $('#' + this.mapDiv.data('haf-hidden-input-id'));
            this.regionStartId = this.mapDiv.data('region-id');
            this.hafPopupUrl = this.mapDiv.data('haf-popup-url');
        }
    }

    class LayerConfig {
        constructor() {
            this.layer = null;
            this.coordinates = null;
            this.zoom = null;
            this.feature = null;

            this.layerGroupRegions = L.layerGroup();
            this.layerGroupHarboursClustered = L.layerGroup();
            this.layerGroupAirports = L.layerGroup();

            this.markerGroupRegions= L.featureGroup();
            this.markerGroupHarboursClustered = L.markerClusterGroup();
            this.markerGroupAirports= L.featureGroup();
        }

        addToMap(map) {
            this.markerGroupRegions.addLayer(this.layerGroupRegions);
            this.markerGroupHarboursClustered.addLayer(this.layerGroupHarboursClustered);
            this.markerGroupAirports.addLayer(this.layerGroupAirports);

            this.markerGroupRegions.addTo(map);
            this.markerGroupHarboursClustered.addTo(map);
            this.markerGroupAirports.addTo(map);
        }

        removeFromMap(map) {
            map.removeLayer(this.markerGroupRegions);
            map.removeLayer(this.markerGroupHarboursClustered);
            map.removeLayer(this.markerGroupAirports);
        }
    }

    // Icons //

    let harbourMarker = L.divIcon({
        className: 'map-div-icon-harbour',
        iconSize: new L.Point(25, 25),
    });

    let harbourMarkerSelected = L.divIcon({
        className: 'map-div-icon-harbour-selected',
        iconSize: new L.Point(25, 25),
    });

    let regionMarker = L.divIcon({
        className: 'map-div-icon-region',
        iconSize: new L.Point(25, 25),
    });

    let airportMarker = L.divIcon({
        className: 'map-div-icon-airport',
        iconSize: new L.Point(25, 25),
    });

    // Variables //

    let mapConfig = new MapConfig('search_map')
    let visibleLayerConfig = undefined;
    let newLayerConfig = undefined;
    let selectedHarbourLayers = [];

    // Map //

    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
    let map = L.map(mapConfig.mapDiv.attr('id'), {
        zoomControl: false,
        doubleClickZoom: false,
        center: mapConfig.latLong,
        zoom: mapConfig.zoom,
    });
    map.gestureHandling.enable();

    L.control.zoom({
        position: 'bottomright'
    }).addTo(map);

    L.control.scale({
        metric: true
    }).addTo(map);

    // Tiles //

    L.tileLayer('https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png', {
        attribution: '<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> | by Barbera Yachting'
    }).addTo(map);

    // Draw start view //

    drawMapLayer(undefined, mapConfig.startUrl);

    // Functions //

    function drawMapLayer(clickedFeature, url) {
        newLayerConfig = new LayerConfig();
        if (clickedFeature !== undefined) {
            // slice(): Kopie von coordinates, reverse(): GeoJSON gibt longLat zurück brauche aber LatLong
            newLayerConfig.coordinates = clickedFeature.properties.mapCenter.slice().reverse();
            newLayerConfig.zoom = clickedFeature.properties.zoom;
            newLayerConfig.feature = clickedFeature
            url = clickedFeature.properties.url;
            refreshBreadCrumb(clickedFeature.properties.xRegionId);
        }
        newLayerConfig.layer = new L.GeoJSON.AJAX(url, {
            middleware: function (data) {
                if (clickedFeature === undefined && data.coordinates !== undefined && data.zoom !== undefined) {
                    map.setView(data.coordinates.slice().reverse(), data.zoom);
                }

                return data;
            },
            onEachFeature: function (feature, layer) {
                addMarker(feature, layer);
                layer.on('click', function () {

                    // Safari: Click Event wird mehrfach ausgelöst
                    // Andere Browser: Dragging funktioniert nicht mehr
                    if (L.Browser.safari) {
                        map.gestureHandling.disable();
                    }
                    switch (feature.properties.markerType) {
                        case 'region':
                            drawMapLayer(feature);

                            handelRegionClick(feature);
                            break;

                        case 'harbour':
                            handleHarbourClick(feature, layer);
                            break;
                    }
                    if (L.Browser.safari) {
                        map.gestureHandling.enable();
                    }
                });
            }
        }).on('data:loaded', function() {
            drawLayerConfig(newLayerConfig);

            // Mit bestimmter Region beginnen
            if (mapConfig.regionStartId) {
                changeRegionById(mapConfig.regionStartId);
                mapConfig.regionStartId = null;
            }
        });
    }

    function drawLayerConfig(layerConfig) {
        layerConfig.layer.eachLayer(function (layer) {
            switch (layer.feature.properties.markerType) {
                case 'region':
                    layerConfig.layerGroupRegions.addLayer(layer)
                    break;

                case 'airport':
                    layerConfig.layerGroupAirports.addLayer(layer)
                    break;

                case 'harbour':
                    layerConfig.layerGroupHarboursClustered.addLayer(layer)
                    break;
            }
        })
        layerConfig.addToMap(map)
        if (layerConfig.coordinates != null && layerConfig.zoom != null) {
            map.setView(layerConfig.coordinates, layerConfig.zoom);
        }
        if (visibleLayerConfig !== undefined) {
            visibleLayerConfig.removeFromMap(map);
        }
        visibleLayerConfig = layerConfig;
    }

    function addMarker(feature, layer) {
        switch (feature.properties.markerType) {
            case 'region':
                layer.setIcon(regionMarker)
                    .bindTooltip(feature.properties.popup, {
                        permanent: true,
                        offset: [0,10],
                        direction: 'bottom',
                        className: 'map-popup-region bg-primary-light border-primary',
                        interactive: true,
                    })
                ;
                break;

            case 'harbour':
                layer.setIcon(harbourMarker)
                    .bindTooltip(feature.properties.name, {
                        offset: [14,0],
                        permanent: true,
                        direction: 'right',
                    })
                ;
                // Mobile keine Popups
                if (!L.Browser.mobile) {
                    let popup = L.responsivePopup();
                    layer.bindPopup(popup, {
                        autoPan: false,
                        permanent: false,
                        autoPanPadding: [10, 40],
                        closeButton: false,
                        offset: [20,20],
                        direction: 'top',
                        maxWidth: 400,
                    });
                    layer.on('mouseover',function () {
                        this.closeTooltip();
                        let el = document.createElement('div');
                        // Popup Content laden, wenn noch nicht geschehen.
                        if (popup.getContent() === undefined) {
                            $.get(mapConfig.hafPopupUrl + '/' + feature.properties.xHafId).done(function(data) {
                                el.innerHTML = data;
                                popup.setContent(el);
                                popup.update();
                            });
                        }
                        this.openPopup();
                    }).on('mouseout', function () {
                        this.closePopup();
                        this.openTooltip();
                    });
                }
                break;

            case 'airport':
                layer.setIcon(airportMarker)
                    .bindTooltip(feature.properties.popup, {
                        permanent: false,
                        offset: [0,-10],
                        direction: 'top',
                    })
                ;
                break;
        }
    }

    function handelRegionClick(feature) {
        clearHarbourList();
        setXRegionSelect(feature.properties.xRegionId);
    }

    function handleHarbourClick(feature, layer) {
        toggleHarbour(layer);
        setXRegionSelect(feature.properties.xRegionId);
    }

    function toggleHarbour(layer) {
        if (selectedHarbourLayers.includes(layer)) {
            layer.setIcon(harbourMarker);
            selectedHarbourLayers.splice(selectedHarbourLayers.indexOf(layer), 1);
        } else {
            layer.setIcon(harbourMarkerSelected);
            selectedHarbourLayers.push(layer);
        }
        refreshHarbourList();
    }

    function clearHarbourList() {
        selectedHarbourLayers.forEach(function (layer) {
            layer.setIcon(harbourMarker);
        });
        selectedHarbourLayers = [];
        refreshHarbourList();
    }

    function refreshHarbourList() {
        let harbourIds = [];
        mapConfig.hafHiddenInput.val(null);
        if (selectedHarbourLayers.length > 0)
        {
            let harbourList = $('#harbour-list');
            harbourList.empty();
            selectedHarbourLayers.forEach(function (harbourLayer, index) {
                harbourIds.push(harbourLayer.feature.properties.xHafId);
                harbourList.append('<li><i class="far fa-times-circle mr-1 text-primary cursor-pointer map-remove-harbour-btn" data-index="' +  index + '"></i>' + harbourLayer.feature.properties.name + '</li>')
            });
            $('#map-harbour-select-container').show();
            $('#map-harbour-select-container-empty').hide();
        } else {
            $('#map-harbour-select-container').hide();
            $('#map-harbour-select-container-empty').show();
        }
        mapConfig.hafHiddenInput.val(harbourIds);
    }

    function refreshBreadCrumb(xRegionId) {
        $.getJSON(mapConfig.breadCrumbUrl + '/' + xRegionId).done(function(data) {
            let breadCrumb = mapConfig.breadCrumb;
            breadCrumb.empty();
            data.forEach(function(data) {
                breadCrumb.append(
                    '<li class="breadcrumb-item">' +
                    '<a class="map-region-link" href="javascript:void(0)" data-region-id="' + data.regionId + '" data-url="' + data.url + '">' + data.name + '</a>' +
                    '</li>'
                );
            });
        });
    }

    function setXRegionSelect(xRegionId) {
        mapConfig.regionSelect.val(xRegionId);
        mapConfig.regionSelect.trigger('change');
    }

    function changeRegionById(xRegionId) {
        if (xRegionId) {
            drawMapLayer(undefined, mapConfig.startUrl + '/' + xRegionId);
            refreshBreadCrumb(xRegionId);
            clearHarbourList();
        }
    }

    // Click events //

    $(document).on('click', '#map-back-btn', function () {
        clearHarbourList();
        if (mapConfig.breadCrumb.find('li').length > 1) {
            mapConfig.breadCrumb.find('li:last-child').remove();
            drawMapLayer(undefined, mapConfig.breadCrumb.find('li:last-child').find('a').data('url'));
        }
    });

    $(document).on('click', '.map-region-link', function () {
        clearHarbourList();
        $(this).parent().nextAll().remove();
        setXRegionSelect(parseInt($(this).data('region-id')));
        drawMapLayer(undefined, $(this).data('url'));
    });

    $(document).on('click', '.map-remove-harbour-btn', function () {
        let harbourLayer = selectedHarbourLayers[$(this).data('index')];
        if (harbourLayer !== undefined) {
            toggleHarbour(harbourLayer);
        }
    });

    $(document).on('click', '.map-clear-harbour-list', function () {
        clearHarbourList();
    });

    // Events //

    // Häfen ausblenden ab Zoom lvl
    map.on('zoomend', function() {
        if (map.getZoom() < 7){
            map.removeLayer(visibleLayerConfig.layerGroupAirports);
        } else {
            map.addLayer(visibleLayerConfig.layerGroupAirports);
        }
    });

    mapConfig.regionSelect.on('select2:select', function () {
        changeRegionById($(this).val());
    });


    // TEST http://c.tile.stamen.com/watercolor/${z}/${x}/${y}.jpg

/*
    function test() {
        let test = $('.nav-menuzord');
        if (test.hasClass('bg-info')) {
            test.removeClass('bg-info');
            test.addClass('bg-danger');
        } else {
            test.addClass('bg-info');
            test.removeClass('bg-danger');
        }
    }
*/

    /*let grayscale = L.tileLayer('https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png', {attribution: '<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> | by A'});
    let baseMaps = {
        "Map A": grayscale,
        //"Map B": L.tileLayer('http://c.tile.stamen.com/watercolor/{z}/{x}/{y}.jpg', {attribution: '<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> | by B'}),
        "Mapbox": L.tileLayer('https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoid2gtc29mdCIsImEiOiJja3pwZXp5ZXYwMWoxMndtcmk3NzdjaW9wIn0.hhe7eLTg76tNYt6iU80o6g', {attribution: '<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> | by B'})
    };
    L.control.layers(baseMaps).addTo(map);*/
